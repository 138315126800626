<template>
  <div id="broker-menu-register">
    <BrokerMenuRegister />
    <Footer />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {
    document.title = "BROKERS.MENU - Registration";
  },
  components: {
    BrokerMenuRegister: () =>
      import("../../components/broker-menus/register/BrokerMenuRegister"),
    Footer: () => import("../../components/broker-menus/Footer"),
  },
};
</script>

<style>
</style>